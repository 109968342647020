import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../../assets/css/Home.css';
import Button from '@mui/material/Button';

function PricingPlan() {
  return (
    <div>
       <Container maxWidth="lg">
        <div className='featureHeading'>
        <div className='main_title text-center'>
            <spam><em></em></spam>
            <h1>Choose Pricing Plan</h1>
        </div>
        <Row className='features' style={{margin: '3rem 0'}}>            

            <Col lg="4">
                <div className='featureColumn location'>
                    <h3>SHARED</h3>
                    <h3>99/-</h3>
                    <p>(Per Slot Per Bike Per Day)</p>
                    <p className='pt-3'>
                        <h5>Digital Signage - 24 INCH</h5>
                        <p style={{marginBottom: '0px'}}>Total No. of Slot - 10</p>
                        <p style={{marginBottom: '0px'}}>Per Slot Time - 30 Sec</p>
                        <p style={{marginBottom: '0px'}}>Per Slot Per Brand - 100 Per day</p>
                    </p>
                    <p className='pt-3'>
                        <h5>Riders - From different food delivery app</h5>
                        <h6>TRACKING OF VEHICLE - LIVE</h6>
                        <p style={{marginBottom: '0px'}}>Proof of Play</p>
                        <p style={{marginBottom: '0px'}}>Content Management System</p>
                        <p style={{marginBottom: '0px'}}>Eyeball Count</p>
                        <div className='mt-4'>
                            <a href='https://forms.gle/KGLR4ozomnjnzjLN9' target='_blank'>
                                <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Buy Now</Button>
                            </a>
                        </div> 
                    </p>
                </div>
            </Col>

            <Col lg="4" className='staticBoard' style={{marginTop: '7%'}}>
                <div className='featureColumn location'>
                    <h3>STATIC BOARD</h3>
                    <h3>99/-</h3>
                    <p>(Per Bike Per Day)</p>
                    <p>
                        <h5>Idel Campaign Hours - 240</h5>
                        <h6>Size - 2ft X 1ft (Static Board)</h6>
                        <p>Riders - From different food delivery app</p>
                        <h6>TRACKING OF VEHICLE - LIVE</h6>
                        <div className='mt-4'>
                        <a href='https://forms.gle/KGLR4ozomnjnzjLN9' target='_blank'>
                            <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Buy Now</Button>
                        </a>
                        </div> 
                    </p>
                </div>
            </Col>

            <Col lg="4">
                <div className='featureColumn location'>
                    <h3>SHARED</h3>
                    <h3>275/-</h3>
                    <p>(Per Adbike Per Hours)</p>
                    <p className='pt-3'>
                        <h5>Digital Signage - 32 INCH</h5>
                        <p style={{marginBottom: '0px', marginBottom: '2.7rem'}}>(with three side branding space)</p>
                        <h6>Ideal Campaign Hours - 120</h6>
                        <h6>Material Distribution</h6>
                        <h6>Dedicated Riders</h6>
                        <h6>Tracking Vechicle - Live</h6>
                        <h6>Proof of Play</h6>
                        <h6>Content Management System</h6>
                        <h6>Eyeball Count</h6>
                        <h6>Location Targeting - As Per Brand</h6>
                    </p>
                    <div className='mt-4'>
                        <a href='https://forms.gle/KGLR4ozomnjnzjLN9' target='_blank'>
                            <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Buy Now</Button>
                        </a>
                    </div>
                </div>
            </Col>

        </Row>
        </div>
       </Container>
    </div>
  )
}

export default PricingPlan
