import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../../assets/css/Home.css';
import Location from '../../assets/images/featureLocation.png';
import Time from '../../assets/images/timeTargeting.png';
import Dynamic from '../../assets/images/dynamicContent.png';
import RealTime from '../../assets/images/realTime.png';

function Features() {
  return (
    <div>
       <Container maxWidth="lg">
       <div className='featureHeading'>
            <div className='main_title text-center'>
                <spam><em></em></spam>
                <h1>Features</h1>
                <h4>Give your media spends larger scale and coverage<br /> than ever before</h4>
            </div>
            <Row className='features'>
                
                <Col lg="3">
                    <div className='featureColumn location'>
                        <img src={Location} alt="Location" width="35%"/>
                        <h5>Location Targeting</h5>
                        <p className='pt-4'>Choose your target location as per your target audience & paly content as per choosed audience and locations.</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn time'>
                    <img src={Time} alt="Time" width="45%"/>
                        <h5>Time Targeting</h5>
                        <p className='pt-4'>Choose time slot relevant to your target audience, & run your campaign 
                        within day of week & hour of day.</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn dynamic'>
                    <img src={Dynamic} alt="Dynamic Content" width="55%" />
                        <h5>Dynamic Content</h5>
                        <p className='pt-4' style={{fontSize: '15px', paddingBottom: '12px'}}>Choose your content as per time, location, audience, weather reports, time
                        and in all possible format. (.mp4, .gif, .jpeg, .png etc.)</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn realTime'>
                    <img src={RealTime} alt="Real Time" width="50%" />
                        <h5>Real-Time Insight</h5>
                        <p className='pt-4'>Real Time insight with location tracking, eyeball count dwell-time of
                        audience & passby audience and visitors.</p>
                    </div>
                </Col>
            </Row>
        </div>
       </Container>
    </div>
  )
}

export default Features
