import React from 'react'
import { Container, Row, Col} from 'reactstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Link} from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function footer() {
  return (
    <div className='footer'>
      <Container>
        <Row>
            <Col lg='3' md='4' sm='6'>
            <h6>QUICK LINKS</h6>
              <ListGroup className='footerList'>
                <ListGroup.Item >About us</ListGroup.Item>
                <ListGroup.Item>For Riders</ListGroup.Item>
                <ListGroup.Item>Privacy Policies</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg='3' md='4' sm='6'>
              <h6>CATEGORIES</h6>
              <ListGroup className='footerList'>
                <ListGroup.Item >Top Categories</ListGroup.Item>
                <ListGroup.Item>Best Rated</ListGroup.Item>
                <ListGroup.Item>Best Price</ListGroup.Item>
                <ListGroup.Item>Latest Submissions</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg='3' md='4' sm='6'>
            <h6>CONTACTS</h6>
              <ListGroup className='footerList-contact'>
                <ListGroup.Item >
                  <span><HomeOutlinedIcon /></span> Saguna more, behind hitech hospital,
                  <br />danapur, Patna Bihar - 801503
                </ListGroup.Item>
                <ListGroup.Item>
                  <span><PhoneAndroidOutlinedIcon /></span> +917352233444
                </ListGroup.Item>
                <ListGroup.Item>
                  <span><MailOutlineOutlinedIcon /></span> info@adbike.in
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg='3' md='4' sm='6'>
              <h6>KEEP IN TOUCH</h6>
              {/* <Form.Label>Please enter a valid email address.</Form.Label>
              <Form.Control type="email" placeholder="Enter email" /> */}
              <div className='mailbox'>
                <input type="email" placeholder='Enter your email' />
                <span>< ChevronRightIcon /></span>
              </div>
              <div className='social-link' style={{ marginTop: '30px' }}>
                <h6>FOLLOW US</h6>
                <div className='social-icon'>
                  <span><a href='http://www.facebook.com/adbikeindia' target='blank'>< FacebookIcon /></a></span>  
                  <span><a href='http://instagram.com/adbikeindia' target='blank'>< InstagramIcon /></a></span>  
                  <span><a href='http://twitter.com/adbikeindia' target='blank'>< TwitterIcon /></a></span>  
                  <span><a href='http://youtube.com/@adbikeindia' target='blank'>< YouTubeIcon /></a></span>  
                  <span><a href='http://linkedin.com/company/adbikeindia' target='blank'>< LinkedInIcon /></a></span> 
                </div>
              </div>
            </Col>
        </Row>
        <hr />
        <row>
          <Col lg='12' md='12' sm='12'>
            <div style={{ textAlign: 'center' }}>
            copyright ©️ ATPL - 2023, all right reserved
            </div>
          </Col>
        </row>
      </Container>
    </div>
  )
}

export default footer
