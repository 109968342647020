import React, { useState, useRef }  from 'react'
import { Container } from '@mui/material';
import { Row, Col } from 'reactstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import aimim from '../../assets/images/clients/aimim-logo.png';
import bjp from '../../assets/images/clients/bjp-logo.png';
import bs from '../../assets/images/clients/bs-logo.png';
import jdu from '../../assets/images/clients/jdu-logo.png';
import ljp from '../../assets/images/clients/ljp-logo.png';
import rjd from '../../assets/images/clients/rjd-logo.png';
import pmyj from '../../assets/images/clients/pmyj-logo.png';
import bsss from '../../assets/images/clients/bsss-logo.png';
import sec from '../../assets/images/clients/sec-logo.png';
import bajaj from '../../assets/images/clients/bajaj-logo.png';
import giz from '../../assets/images/clients/giz-logo.png';
import pi from '../../assets/images/clients/pi-logo.png';
import mahavidya from '../../assets/images/clients/mahavidya-logo.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Client = () => {
    const [value, setValue] = React.useState('1');
    const [carouselShow, setCarouselShow] = useState(5);
    const carouselRef = useRef(null);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };

      const responsive1 = {
        
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };

      const responsive2 = {
        
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };

  return (
    <div style={{ backgroundPosition: 'center', padding: '80px 0px'}}>
       <Container maxWidth="lg">
        <div className='main_title text-center'>
            <spam><em></em></spam>
            <h1>Our Clients</h1>
            {/* <h4>We’ve built solutions for...</h4> */}
        </div>
        <Box sx={{ typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ width: '96%', typography: 'body1', margin: 'auto' }}>
                <TabList onChange={handleChange}  textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example" centered>
                    <Tab label="Political" value="1" />
                    <Tab label="Government" value="2" />
                    <Tab label="Corporate" value="3" />
                </TabList>
                </Box>
                <TabPanel value="1">
                    
                    <Carousel responsive={responsive} ref={carouselRef} show={carouselShow} slide={2} swiping={true} arrows showArrows={true}>
                        
                        <div className='client-box'>
                            <img src={bjp} alt="bjp logo" width="100%"/>
                        </div>
                        <div className='client-box'>
                            <img src={bs} alt="bs logo" width="100%" />
                        </div>
                        <div className='client-box'>
                            <img src={jdu} alt="jdu logo" width="100%" />
                        </div>
                        <div className='client-box'>
                            <img src={ljp} alt="ljp logo" width="100%" />
                        </div>
                        <div className='client-box'>
                            <img src={rjd} alt="rjd logo" width="100%" />
                        </div>
                        <div className='client-box'>
                            <img src={aimim} alt="aimim logo" width="100%"/>
                        </div>

                    </Carousel>
                </TabPanel>
                
                <TabPanel value="2">
                <Carousel responsive={responsive1} ref={carouselRef} show={carouselShow} slide={2} swiping={true} arrows showArrows={true}>
                        <div className='client-box text-center'>
                            <img src={pmyj} alt="pmyj logo" width="60%"/>
                        </div>
                        <div className='client-box text-center '>
                            <img src={bsss} alt="bsss logo" width="60%" />
                        </div>
                        <div className='client-box'>
                            <img src={sec} alt="sec logo" width="100%" style={{padding: '20% 0px'}} />
                        </div>
                    
                    </Carousel>
                </TabPanel>

                <TabPanel value="3" className='corporatePanel'>
                     <Carousel responsive={responsive2} ref={carouselRef} show={carouselShow} slide={2} swiping={true} arrows showArrows={true}>
                        <div className='client-box text-center'>
                            <img src={bajaj} alt="bajaj logo" width="80%" style={{padding: '8% 0px'}}/>
                        </div>
                        <div className='client-box text-center '>
                            <img src={giz} alt="giz logo" width="100%" style={{padding: '19% 0px'}}/>
                        </div>
                        <div className='client-box text-center'>
                            <img src={pi} alt="pi logo" width="64%" />
                        </div>
                        <div className='client-box text-center'>
                            <img src={mahavidya} alt="mahavidya logo" />
                        </div>
                        
                    </Carousel>
                </TabPanel>
            </TabContext>
        </Box>
        
       </Container>
    </div>
  )
}

export default Client
