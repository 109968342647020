import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../../assets/css/Home.css';
import KM from '../../assets/images/KMTravelled.png';
import ProofOfPlay from '../../assets/images/ProofOfPlay.png';
import areaCovered from '../../assets/images/areaCovered.png';
import RealTime from '../../assets/images/realTime.png';

function KeyPerformance() {
  return (
    <div>
       <Container maxWidth="lg">
       <div className='featureHeading'>
            <div className='main_title text-center '>
                <spam><em></em></spam>
                <h1>Key Performance Indicator On Dashboard</h1>
                <h4>Not All Ad Spends is Added Spends Rather A Value Added Spends</h4>
            </div>
            <Row className='features'>
                
                <Col lg="3">
                    <div className='featureColumn km'>
                        <img src={KM} alt="Total KM Travelled" width="60%"/>
                        <h5>Total KM Travelled</h5>
                        <p className='pt-4'>Track the total distance travelled in all the cities.</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn proof'>
                    <img src={ProofOfPlay} alt="Proof Of Play" width="55%"/>
                        <h5>Proof Of Play</h5>
                        <p className='pt-4'>Track the number of time your content played.</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn area'>
                    <img src={areaCovered} alt="Area Covered" width="55%" />
                        <h5>Area Covered</h5>
                        <p className='pt-4'>Get actionable insight of top performing locations.</p>
                    </div>
                </Col>

                <Col lg="3">
                    <div className='featureColumn real'>
                    <img src={RealTime} alt="Real Time" width="50%" />
                        <h5>Real-Time Insight</h5>
                        <p className='pt-4' style={{fontSize: '15px', paddingBottom: '6px'}}>Smart technology to count impact and impressions.</p>
                    </div>
                </Col>
            </Row>
        </div>
       </Container>
    </div>
  )
}

export default KeyPerformance
