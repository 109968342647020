import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Container } from '@mui/material';
import Layout from '../components/Header/Layout';
import Banner from '../assets/images/slide-1A.jpg';
import Banner2 from '../assets/images/slide-2A.jpg';
import Bike from '../assets/images/bike.gif';
import Bike2 from '../assets/images/bike2.png';
import hoarding from '../assets/images/hoarding.png';
import joinAdbike from '../assets/images/joinAdbike-img.png';
import fullScape from '../assets/images/full-scape.jpg';
import brandStory from '../assets/images/brandStory.png';
import urbanDesign from '../assets/images/urbanDesign.png';
import ruralDesign from '../assets/images/ruralDesign.png';
import '../assets/css/style.css';
import Features from '../components//HomePage/Features';
import KeyPerformance from '../components//HomePage/KeyPerformance';
import Client from '../components//HomePage/Client';
import Footer from '../components/Footer/Footer';
import { Row, Col } from 'reactstrap';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';
import { Link as ScrollLink, Element, animateScroll as scroll, scroller } from 'react-scroll';
import PricingPlan from '../components/HomePage/PricingPlan';

const Home = () => {
  
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>ADBIKE- INDIA'S PROGRAMMATIC DIGITAL MOVING BILLBOARD ON BIKE</title>
        <meta name="description" content="Adbike is India’s First & only “Transit Advertising Platform” on Bike, ‘OOH Media on Bike’, ‘LED BIKE PRACHAR RATH’, which is the ‘Best BTL CAMPAIGN’ platform to ‘cover audience in TIER 1 to TIER 6 cities. ’ADBIKE’ is only company which provide real time Audience Measurement, Proof Of Play, Real-Time Tracking System in all available ‘TRANSIT ADVERTISEMENT PLATFORM’ in INDIA." />
        <meta name="keywords" content="adbike.in, Led Bike Prachar Rath, Political campaigning, OOH, Dooh, Outdoor industry, BTL Campaign, BTL, Innovative marketing tool, marketing tool, marketing, advertisement, Digital Dooh, Footfall count, OOH buying, Media Planning, India’s first adbike taxi, political prachar, ayushman bharat campaign, IEC campaign, rural marketing, last mile advertisement." />
      </Helmet>
      <div className='home' style={{ backgroundImage: `url(${Banner})`, Color: '#faf3cc'}}>
      
        <Container maxWidth="lg">
          <div className='headerContainer'>
            <Row>
              <Col lg={6} sm={12}>
                <h4 style={{textAlign: 'left'}}><b>INDIA'S FIRST</b></h4>
                <h1>Programmatic digital <br />moving billboard</h1>
                <h4>Take Your Brand To The Lastmile</h4>
                <h1>ADBIKE</h1>
              </Col>
              <Col lg={6} className='bike-img'>
                <img src={Bike} alt="Real Time" width="65%" style={{marginBottom: '-70px'}} />
              </Col>
            </Row>
            
          </div>
        </Container>
      </div>
      
      {/* features */}
      <Features/>

      {/* King of pin banner */}
      <div className='home banner2' style={{ backgroundImage: `url(${Banner2})`, Color: '#faf3cc', margin: '20px 0px'}}>
          <Container maxWidth="lg">
            <div className='headerContainer headerContainer2'>
              <Row>
                <Col lg={6} sm={12} style={{textAlign: 'left'}}>
                  <h1 style={{}}><b>KING OF PIN !</b></h1>
                  <h4>Be the well-known brand<br />in every pincode</h4>                
                  <h2>Advertise on ADBIKE</h2>
                  <h4>Increase sales</h4>
                </Col>
                <Col lg={6} className='bike-img'>
                  <img src={Bike2} alt="Adbike" width="65%" />
                </Col>
              </Row>
              
            </div>
          </Container>
      </div>
      
      {/* adbike full-scape */}
      <div className='home banner2'>
          <Container maxWidth="lg">
            <div className='headerContainer full-scape'>
              <Row>
                
                <Col lg={6} style={{ textAlign: 'left' }}>
                  <img src={fullScape} alt="Adbike" width="80%" style={{borderRadius: '20px'}} />
                </Col>
                <Col lg={6} sm={12} style={{textAlign: 'left', color: '#000'}}>
                  <div className='main_title text-center mt-5'>
                      <spam style={{margin: 'initial', marginBottom: '10px'}}><em></em></spam>
                  </div>
                  <h2>Adbike Full-Scape</h2>
                  <h5>Adbike is an unique advertising platform, assembled with wide led screen, audio-jingels,
                    a long power-backup of 8 hours, and branding space from three side. 
                  </h5>
                  <h5>Adbike FULL-SCAPE, is one of it’s design for SINGLE BRAND at a time where we customize dedicated
                    campaign for a single brands or organizations. 
                  </h5>     
                  <div className='mt-5'>
                    <a href='https://forms.gle/KGLR4ozomnjnzjLN9' target='_blank'>
                      <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Contact Us</Button>
                    </a>
                  </div>           
                </Col>              
              </Row>
              
            </div>
          </Container>
      </div> 
      

      {/* why adbike banner */}      
        <div className='home' style={{ backgroundImage: `url(${Banner})`, Color: '#faf3cc',}}>
          <Container maxWidth="lg">
            <div className='headerContainer headerContainer2 whyAdbike'>
              <Row>
                <Col lg={6} sm={12} style={{textAlign: 'left'}}>
                  <h2 className='pb-4'><b>Why ADBIKE?</b></h2>
                  <h4><ArrowRightIcon />Customized Content As Per Location/audience</h4>                
                  <h4><ArrowRightIcon />Last-mile Reach</h4>
                  <h4><ArrowRightIcon />Real-time Tracking</h4>
                  <h4><ArrowRightIcon />Eyeball & Impression Count</h4>
                  <h4><ArrowRightIcon />Dwell-time Insights Of Audience</h4>
                  <h4><ArrowRightIcon />Dynamic Pricing System</h4>
                  <div className='mt-3 ml-4'>
                      <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Book Now</Button>
                  </div>
                </Col>  
                <Col lg={6} className='bike-img'>
                  <img src={Bike} alt="Real Time" width="65%" style={{marginBottom: '-77px'}} />
                </Col>
              </Row>
              
            </div>
          </Container>
        </div>

      
      {/* key Performance */}
      <KeyPerformance/>

      {/* hoarding */}
      <div className='home banner2' style={{ backgroundImage: `url(${Banner2})`, Color: '#faf3cc'}}>
        <Container maxWidth="lg">
          <div className='headerContainer headerContainer2 hoarding'>
            <Row>
              <Col lg={6} sm={12} style={{textAlign: 'left'}}>
                <h1><b>HOARDING !</b></h1>
                <h4>Cover On-Road & Street Audience<br/> In The Price Of Single Traditional</h4>                
                <h2>Advertise on ADBIKE</h2>
                <h4>It can cover all the prossible massive audience location</h4>
              </Col>
              <Col lg={6} className='bike-img hoarding'>
                <img src={hoarding} alt="Adbike" width="100%" />
              </Col>
            </Row>
            
          </div>
        </Container>
      </div>
      
      {/* join adbike now */}
      <div className='home banner2'>
        <Container maxWidth="lg">
          <div className='headerContainer full-scape'>
            <Row>
              <Col lg={6} sm={12} style={{textAlign: 'left', color: '#000'}}>
                <div className='main_title text-center mt-5'>
                    <spam style={{margin: 'initial', marginBottom: '10px'}}><em></em></spam>
                </div>
                <h2>Join Adbike</h2>
                <h5>While Working As Delivery Partner For Other Companies. 
                </h5>
                <h5>We Provide E-Scooty & E-Cycle enabled with Digital Billboard Which Help Delivery Partner To
                  Increase their income over commissions. 
                </h5> 
                <h5>
                We also have solutions for partner’s who have self-owned vechiles. 
                </h5>
                <div className='mt-5'>
                    <a href='https://forms.gle/1u9xHDDHNimWo3BHA' target='_blank'>
                      <Button variant="contained" style={{boxShadow: '2px 2px 0px 2px #efe4e4'}}>Join Now</Button>
                    </a>  
                </div> 
              </Col>
              <Col lg={6} className='joinAdbike'>
                <img src={joinAdbike} alt="Adbike" width="70%" style={{borderRadius: '20px'}} />
              </Col>
            </Row>
            
          </div>
        </Container>
      </div>

      {/* Deliver Yourbrand story */}
      <div className='home banner2' style={{ backgroundColor: '#f4f3f3' }}>
        <Container maxWidth="lg">
          <div className='headerContainer headerContainer2 hoarding'>
            <Row>
              <Col lg={6} className='' style={{textAlign: 'left'}}>
                <img src={brandStory} alt="Adbike" width="80%" />
              </Col>
              <Col lg={6} sm={12} style={{textAlign: 'left', color: '#000'}}>
                <div className='main_title text-center' style={{marginTop: '40px'}}>
                    <spam style={{margin: 'initial', marginBottom: '10px'}}><em></em></spam>
                </div>
                <h4 style={{fontSize: '1.9rem', fontWeight: '700'}}>Deliver your brand story with every delivery in INDIA</h4>
                <h6 style={{fontWeight: '400'}}>With the advent of online marketplace and food deliveries platform, there has been a huge
impact of delivery personals having coverage and reachability to every geocode of a city or
location belonging to TIER I to TIER VI. Adbike has a huge network of these delivery partners
on board who are successfully reaching to every latitude & longitude of any location along
with their advertising platform..  
                </h6>
                <br/>
                <h6 style={{fontWeight: '400'}}>Adbike being an advertising partner, can take your brand story to every nook &amp; corner in an specific
& précised location and help you engage with more & more target audience in turn increasing your
brand identity.
                </h6> 
                <hr style={{borderTop: '2px solid rgba(0,0,0,.1)'}} />
                <Row>
                  <Col>
                    <div style={{width: '20%', float: 'left'}}><h5>10+</h5></div>
                    <div><h5>Cities</h5></div>
                  </Col>
                </Row>
                <Row>  
                  <Col>
                    <div style={{width: '20%', float: 'left'}}><h5>2000+</h5></div>
                    <div><h5>Riders On Board</h5></div>
                  </Col>
                </Row>
              </Col>             
            </Row>
            
          </div>
        </Container>
      </div>
              
      {/* pricing Plan */}
      <PricingPlan/>

      {/* We have Solution For Tier 1 To Tier 6 */}
      <div className='featureHeading' style={{ backgroundColor: '#f4f3f3' }}>
        <Container maxWidth="lg">
          <div className='main_title text-center pb-4'>
              <spam><em></em></spam>
              <h1>We have Solution For Tier 1 To Tier 6</h1>
          </div>
          <div className='full-scape'>
            <Row>
              <Col lg={6} className='home tier' style={{ backgroundImage: `url(${urbanDesign})` }}>
                <h2>Urban Design</h2>
              </Col>
              <Col lg={6} className='home tier' style={{ backgroundImage: `url(${ruralDesign})` }}>
                <h2>Rural Design</h2>
              </Col>
            </Row>
            
          </div>
        </Container>
      </div>

      {/* client */}
      <Client />

      {/* footer */}
      <Footer />
      
    </Layout>

  )
}

export default Home
